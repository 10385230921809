// import {useState, useEffect, useRef} from 'react';
// import { socket } from './Socket'
// import './App.css';
// import { AiOutlineClose, AiOutlineMenu} from 'react-icons/ai'

// // function chat_display(chat) {
// //   return (
// //     <div>
// //       <p><b>{chat.user}:</b>{chat.text}</p>
// //       {chat.user === "assistant" && <p><button onClick={this.showSources}>Show Sources</button></p>}

// //     </div>
// //   );
// // }

// function SourceDisplay(props) {
//   return (
//     <div>
//       <h3 className="text-xl font-bold"><a class="underline" href={props.link}>{props.title}</a></h3>
//       <p>...{props.text}{props.text.length < 400 ? "" : "..."}</p>
//     </div>
//   );
// }

// function ChatDisplay(props) {

//   const [showSources, setShowSources] = useState(false);

//   return (
//     <div>
//     {props.user === "user" ? 
//         //Add red box around user chat
//         // <div className="m-auto w-4/5 text-right p-7 bg-red-400 ">
//         <div className="m-auto text-left p-7 bg-red-400 border-8 border-red-800 border-solid flex" ref={props.refProp}>
//           <div className="flex-1">
//             {props.text.split("\n").map((text) => <p className="ml-16 mr-4 text-right">{text}</p>)}
//           </div>
//           <div className="ml-auto flex flex-col items-center">
//             <div class="ml-auto w-16 h-16 bg-red-600 rounded-full flex items-center justify-center">
//               <p className="text-4xl">🧑</p>
//             </div>
//             <p className="text-center mt-2"><b>User</b></p>
//           </div>
//         </div>
    
//     : 
//         <div>
//           <div className="m-auto text-left bg-red-200 p-7 border-8 border-red-800 border-solid md:flex" ref={props.refProp}>
//             <div className="mr-auto flex flex-col items-center">
//               <div class="mr-auto w-16 h-16 bg-red-900 rounded-full"><img src="/chattpi_720.png" alt="ChatTPI"/></div>
//             </div>
//             <div className="flex-1">
//               {props.text.split("\n").map((text) => <p className="mr-16 ml-4 text-left">{text}</p>)}
//             </div>
//             <p><button className="my-2 text-center px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg" onClick={() => setShowSources(!showSources)}>{!showSources ? "Show Sources" : "Hide Sources"}</button></p>
//           </div>
//           {showSources && <div className='m-auto text-left bg-blue-200 p-7 border-8 border-blue-800 border-solid'>
//             <h2 className="text-xl font-bold">Sources:</h2>
//             {props.sources && props.sources.map((source) => <SourceDisplay title={source.title} text={source.text} link={source.link} />)}
//             </div>
//             }
//         </div>
//     }
//     </div>
//   );
// }

// function App() {
//   const chatRef = useRef([]);
//   const lastRef = useRef(null);
//   const [prompt, setPrompt] = useState('');
//   const [chat, setChat] = useState([]);
//   const [nav, setNav] = useState(false);

//   useEffect(() => {
//     (chat.length !== 0 && chat[chat.length-1].user === 'user') && socket.emit('submit_chat', {"chat": chat});
//   }, [chat]);

//   useEffect(() => {
//     chatRef.current = chat;
//   }, [chat]);

//   useEffect(() => {
//     (chat.length !== 0 && chat[chat.length-1].user === 'user') && lastRef.current?.scrollIntoView({ behavior: 'smooth' });
//   }, [chat]);

//   useEffect(() => {
//     function onHyDE(value) {
//         setChat([...chatRef.current, {user: "assistant", text: value, sources: null}])
//     }
//     function onSources(value) {
//       // Set the sources for the last chat message
//       setChat([...chatRef.current.slice(0, -1), {user: "assistant", text: chatRef.current[chatRef.current.length-1].text, sources: value}])
//     }

//     function onChunk(value) {
//       console.log(value)
//         if (chatRef.current !== [] && chatRef.current[chatRef.current.length-1].text !== "Reviewing sources...") {
//           setChat([...chatRef.current.slice(0, -1), {user: "assistant", text: chatRef.current[chatRef.current.length-1].text + value, sources: chatRef.current[chatRef.current.length-1].sources}])
//         }
//         else {
//           setChat([...chatRef.current.slice(0, -1), {user: "assistant", text: value, sources: chatRef.current[chatRef.current.length-1].sources}])
//         }
//     }

//     function onConnect() {
//       console.log('connected');
//     }
//     function onError(error) {
//       console.log(error);
//       alert(error);
//     }
//     socket.on('HyDE', onHyDE)
//     socket.on('sources', onSources)
//     socket.on('output', onChunk)
//     socket.on('connect', onConnect)
//     socket.on('error', onError)
//     return () => {
//       socket.off("HyDE", onHyDE);
//       socket.off("sources", onSources);
//       socket.off("output", onChunk);
//       socket.off("connect", onConnect);
//     };
//       // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   const submitChat = () => {
//     setChat(chat => chat.concat({user: "user", text: prompt, sources: null}));
//     setPrompt('');
//   }

//   const clearChat = () => {
//     setChat([]);
//   }

// 	const handleNav = () => {
// 		setNav(!nav);
// 	}

//   return (
//     <div className="min-h-screen flex flex-col bg-zinc-800">
//       <div className='m-auto pt-5 pb-3 w-9/12'>
//         <div className="text-red-600">  
//           <p><img className="align-baseline inline w-24 h-24" src="/chattpi_720.png" alt="ChatTPI"/> by the  <a href="https://techpolicyinstitute.org">Technology Policy Institute</a></p>
//         </div>
//         <p className="text-white pt-1">Chat with TPI's archive of sources</p>
//       </div>
//       {/* <div onClick={handleNav}>
//         {nav ? <AiOutlineClose size={40} className='fixed right-[5rem] top-10 z-50 text-white hover:text-red-600 hover:cursor-pointer'/> 
//         : <AiOutlineMenu size={40} className='fixed right-[5rem] top-10 z-50 text-white hover:text-red-600 hover:cursor-pointer duration-300'/>}
//       </div>
//       <div className={nav ? 'fixed right-0 top-0 w-[25rem] h-full border-l-4 border-red-600 bg-zinc-800 z-40 ease-in-out duration-[250ms]' 
//       : 'fixed top-0 w-[25rem] h-full bg-zinc-800 z-40 ease-in duration-[400ms] right-[-100%]'}>
//         <ul className='pt-28 text-white text-2xl'>
//           <li className="p-5 border-b-4 border-red-600 hover:cursor-pointer hover:text-red-600 duration-300 active:text-red-600" id = "link" href="#scatter">TPI Website</li>
//           <li className="p-5 border-b-4 border-red-600 hover:cursor-pointer hover:text-red-600 duration-300 active:text-red-600" id = "link" href="#scatter">About ChatTPI</li>
//           <li className="p-5 border-b-4 border-red-600 hover:cursor-pointer hover:text-red-600 duration-300 active:text-red-600" id = "link" href="#top">Disclaimer</li>
//           <li className="p-5 border-b-4 border-red-600 hover:cursor-pointer hover:text-red-600 duration-300 active:text-red-600" id = "link" href="/sources">Contact Us</li>
//         </ul>
//       </div> */}
//       <div className='m-auto w-9/12 flex-grow overflow-y-scroll pb-40'>
//         { chat.length !== 0 && chat.map((chat, i) => <ChatDisplay user={chat.user} text={chat.text} sources={chat.sources} refProp={lastRef} />)}
//         {/* { chat.length === 0 && <br />} */}
//         { chat.length === 0 && <p className='text-white'>Disclaimer: ChatTPI is an AI-based chatbot we developed to help users learn from TPI's 15-year collection of research, events, podcasts, and more. However, please note that because ChatTPI is based on generative AI, the responses it provides may not necessarily reflect the views, opinions, or positions of our organization.</p>}
//         { chat.length === 0 && <p className='text-white'>We strongly recommend that users check the sources ChatTPI provides and also independently verify information.</p>}
//         { chat.length === 0 && <p className='text-white'>If you have any concerns or feedback regarding the chatbot's responses, please contact Nathaniel Lovin at nlovin@techpolicyinstitute.org.</p>}
//       </div>
      
//       <div className="fixed bottom-0 w-full text-center items-center justify-center">
//         <div className="relative flex flex-col w-3/5 mx-auto">
//         <textarea className="p-2.5 flex-grow text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" rows="2" cols="100" value={prompt} onChange={(e) => setPrompt(e.target.value)} />
//         <button className="absolute mt-2 mr-1 md:mr-2 text-center self-end px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded-lg" onClick={submitChat}>Submit</button>
//         </div>
//         <button className="my-2 text-center px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded-lg" onClick={clearChat}>Clear</button>
//       </div>
//     </div>
//   );

// }

// export default App;

import { useState, useEffect, useRef, memo, useCallback } from 'react';
import { socket } from './Socket';
import { AlertCircle, Send, Trash2, ExternalLink, ChevronDown, ChevronUp, Edit2, RefreshCcw } from 'lucide-react';
import { ErrorBoundary, ChatMessageErrorBoundary } from './ErrorBoundary';
import ReactMarkdown from 'react-markdown';

const SourceDisplay = memo(({link, title, date, text }) => (
    <div className="mb-6 last:mb-0">
      <div className="flex items-start gap-2">
        <h3 className="text-lg font-medium flex-1">
          <a className="text-blue-600 hover:text-blue-800 underline inline-flex items-center gap-1" href={link} target="_blank" rel="noopener noreferrer">
            {title}
            <ExternalLink className="w-4 h-4" />
          </a> {date && <span className="text-sm text-gray-600">({date})</span>}
        </h3>
      </div>
      {/* <p className="text-sm text-gray-600 mt-1">{source} {year}</p> */}
      <p className="mt-2 text-gray-800">
        ...{text}{text.length < 200 ? "" : "..."}
      </p>
    </div>
  ));

const timestampToSeconds = (timestamp, link) => {
  if (!timestamp) return 0;
  
  // Format should be HH:MM:SS
  const parts = timestamp.split('.')[0].split(':');
  let seconds = 0;
  
  if (parts.length === 3) {
    // Hours, minutes, seconds
    seconds = parseInt(parts[0]) * 3600 + parseInt(parts[1]) * 60 + parseInt(parts[2]);
  } else if (parts.length === 2) {
    // Minutes, seconds
    seconds = parseInt(parts[0]) * 60 + parseInt(parts[1]);
  } else if (parts.length === 1) {
    // Just seconds
    seconds = parseInt(parts[0]);
  }

  const offsets = {"NbbhvxygnMQ": 10, "EJpkYU_vVe8": 3, "ZqLTx5CPPPk": 0, "TqpnSBxAnhk": 10, "1PEhhqjccE0": -2, "kbFYWLk8BIg": -3}
  const offset = offsets[link]
  
  return seconds - offset;
};

const SpectrumSourceDisplay = memo(({link, title, text, timestamp }) => {
  const timeStampSeconds = timestampToSeconds(timestamp, link);
  return (
    <div className="mb-6 last:mb-0">
      <div className="flex items-start gap-2">
        <h3 className="text-lg font-medium flex-1">
          <a className="text-blue-600 hover:text-blue-800 underline inline-flex items-center gap-1" href={'https://youtube.com/watch?v=' + link + "&t=" + timeStampSeconds} target="_blank" rel="noopener noreferrer">
            {title}
            <ExternalLink className="w-4 h-4" />
          </a>
        </h3>
      </div>
      <p className="mt-2 text-gray-800">
        ...{text}{text.length < 200 ? "" : "..."}
      </p>
    </div>
  );
});

const MessageText = memo(({ text }) => (
  // <>
  //   {text.split("\n").map((line, i) => (
  //     <p key={i} className="text-gray-800">{line}</p>
  //   ))}
  // </>
  <ReactMarkdown
    className="prose prose-red max-w-none text-gray-800"
    components={{
      // Customize link rendering to open in new tab
      a: ({ node, ...props }) => (
        <a target="_blank" rel="noopener noreferrer" {...props} />
      ),
      // Ensure code blocks maintain proper styling
      code: ({ node, inline, ...props }) => (
        <code className={`${inline ? 'bg-gray-100 rounded px-1' : 'block bg-gray-100 p-2 rounded'}`} {...props} />
      )
    }}
  >
    {text}
  </ReactMarkdown>
));

const EditableMessage = memo(({ text, onSave, onCancel }) => {
  const [editedText, setEditedText] = useState(text);
  
  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(editedText);
  };

  return (
    <form onSubmit={handleSubmit} className="flex-1">
      <textarea
        value={editedText}
        onChange={(e) => setEditedText(e.target.value)}
        className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-red-500 focus:border-transparent"
        rows={Math.min(editedText.split('\n').length, 10)}
        autoFocus
      />
      <div className="flex gap-2 mt-2">
        <button
          type="submit"
          className="px-3 py-1 bg-red-500 text-white rounded-lg hover:bg-red-600"
        >
          Save
        </button>
        <button
          type="button"
          onClick={onCancel}
          className="px-3 py-1 border border-gray-300 rounded-lg hover:bg-gray-50"
        >
          Cancel
        </button>
      </div>
    </form>
  );
});

const Sources = memo(({ sources, isSpectrum }) => (
  <div className="mt-4 bg-red-50 rounded-2xl p-6">
    <h2 className="text-lg font-semibold mb-4">Sources</h2>
    {sources.map((source, i) => (
      isSpectrum ? 
        <SpectrumSourceDisplay key={i} {...source} /> : 
        <SourceDisplay key={i} {...source} />
    ))}
  </div>
));

const ChatMessage = memo(({ user, text, sources, refProp, messageId, onEdit, onRetry, isSpectrum }) => {
  const [showSources, setShowSources] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  
  const toggleSources = useCallback(() => {
    setShowSources(prev => !prev);
  }, []);

  const handleEdit = useCallback(() => {
    setIsEditing(true);
  }, []);

  const handleRetry = useCallback(() => {
    onRetry(messageId);
  }, [messageId, onRetry]);

  const handleSave = useCallback((newText) => {
    onEdit(messageId, newText);
    setIsEditing(false);
  }, [messageId, onEdit]);

  const handleCancel = useCallback(() => {
    setIsEditing(false);
  }, []);

  const messageContent = isEditing ? (
    <EditableMessage
      text={text}
      onSave={handleSave}
      onCancel={handleCancel}
    />
  ) : (
    <MessageText text={text} />
  );

  if (user === "user") {
    return (
      <div className="mb-6">
        <div className="flex gap-4 items-start group" ref={refProp}>
          <div className="flex-1 relative">
            <div className="bg-red-100 rounded-2xl p-6 shadow-sm">
              {messageContent}
            </div>
            {!isEditing && (
              <button
                onClick={handleEdit}
                className="absolute right-2 bottom-2 flex items-center gap-1 text-sm text-gray-400 hover:text-gray-600 opacity-0 group-hover:opacity-100 transition-opacity bg-red-50/80 rounded-md px-2 py-1"
              >
                <Edit2 className="w-3 h-3" /> Edit
              </button>
            )}
          </div>
          <div className="w-12 h-12 bg-red-600 rounded-full flex items-center justify-center flex-shrink-0">
            <span className="text-2xl">🧑</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <ChatMessageErrorBoundary messageId={messageId} onRetry={onRetry}>
    <div className="mb-6" ref={refProp}>
      <div className="flex gap-4 items-start group">
        <div className="w-12 h-12 bg-red-900 rounded-full flex items-center justify-center flex-shrink-0">
          <span className="text-2xl"><img src="/chattpi_720.png" alt="ChatTPI"/></span>
        </div>
        <div className="flex-1 relative">
          <div className="bg-white rounded-2xl p-6 shadow-sm">
            <MessageText text={text} />
            {sources?.length > 0 && (
              <button
                className="mt-4 inline-flex items-center gap-2 text-sm text-red-600 hover:text-red-800 transition-colors"
                onClick={toggleSources}
              >
                {showSources ? <ChevronUp className="w-4 h-4" /> : <ChevronDown className="w-4 h-4" />}
                {showSources ? "Hide Sources" : "Show Sources"}
              </button>
            )}
            {text !== "Reviewing sources..." && (
              <button
                onClick={handleRetry}
                className="absolute right-2 bottom-2 flex items-center gap-1 text-sm text-gray-400 hover:text-gray-600 opacity-0 group-hover:opacity-100 transition-opacity bg-white/80 rounded-md px-2 py-1"
              >
                <RefreshCcw className="w-3 h-3" /> Regenerate
              </button>
            )}
          </div>
          {showSources && sources?.length > 0 && <Sources sources={sources} isSpectrum={isSpectrum} />}
        </div>
      </div>
    </div>
    </ChatMessageErrorBoundary>
  );
});

const TypingIndicator = memo(() => (
  <div className="flex gap-4 items-start opacity-60">
    <div className="w-12 h-12 bg-red-800 rounded-full flex-shrink-0 overflow-hidden">
    <img src="/chattpi_720.png" alt="ChatTPI" className="w-full h-full object-cover" />
    </div>
    <div className="flex-1">
      <div className="bg-white rounded-2xl p-6 shadow-sm">
        <div className="flex gap-2">
          <span className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '0ms' }} />
          <span className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '150ms' }} />
          <span className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '300ms' }} />
        </div>
      </div>
    </div>
  </div>
));

const VirtualizedChatContainer = ({ chat, lastRef, isTyping, onEdit, onRetry, isSpectrum }) => {
  const containerRef = useRef(null);
  
  useEffect(() => {
    const options = {
      root: containerRef.current,
      rootMargin: '600px',
      threshold: 0
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        const messageEl = entry.target;
        if (entry.isIntersecting) {
          messageEl.style.visibility = 'visible';
        } else {
          messageEl.style.visibility = 'hidden';
        }
      });
    }, options);

    const messages = containerRef.current.querySelectorAll('.chat-message');
    messages.forEach(msg => observer.observe(msg));

    return () => observer.disconnect();
  }, [chat.length]);

  return (
    <div 
      ref={containerRef} 
      className="flex-1 px-6 py-8" //overflow-y-auto
    >
      {chat.map((msg, i) => (
        <div key={i} className="chat-message">
          <ChatMessage
            messageId={i}
            user={msg.user}
            text={msg.text}
            sources={msg.sources}
            status={msg.status}
            refProp={i === chat.length - 1 ? lastRef : null}
            onEdit={onEdit}
            onRetry={onRetry}
            isSpectrum={isSpectrum}
          />
        </div>
      ))}
      {isTyping && <TypingIndicator />}
    </div>
  );
};

function App() {
  const chatRef = useRef([]);
  const lastRef = useRef(null);
  const inputRef = useRef(null);
  const [prompt, setPrompt] = useState('');
  const [chat, setChat] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  
  // Determine if we're in spectrum mode and what kind based on URL path
  const pathname = window.location.pathname;
  const [isSpectrum, setIsSpectrum] = useState(pathname.includes('/spectrum-series'));

  const sendHeightToParent = () => {
    const height = document.documentElement.scrollHeight;
    window.parent.postMessage({ type: 'resize', height: height }, '*');
  }

  // Socket.io event handlers
  useEffect(() => {
    function onHyDE(value) {
      setChat([...chatRef.current, { user: "assistant", text: value, sources: [] }]);
      setIsTyping(false);
      sendHeightToParent();
    }

    function onSources(value) {
      setChat([...chatRef.current.slice(0, -1), {
        user: "assistant",
        text: chatRef.current[chatRef.current.length - 1].text,
        sources: value
      }]);
      sendHeightToParent();
    }

    function onChunk(value) {
      if (chatRef.current.length !== 0 && chatRef.current[chatRef.current.length - 1].text !== "Reviewing sources...") {
        setChat([...chatRef.current.slice(0, -1), {
          user: "assistant",
          text: chatRef.current[chatRef.current.length - 1].text + value,
          sources: chatRef.current[chatRef.current.length - 1].sources
        }]);
      } else {
        setChat([...chatRef.current.slice(0, -1), {
          user: "assistant",
          text: value,
          sources: chatRef.current[chatRef.current.length - 1].sources
        }]);
      }
      sendHeightToParent();
    }

    function onError(error) {
      console.error('Socket error:', error);
    }
  
    socket.on('HyDE', onHyDE);
    socket.on('sources', onSources);
    socket.on('output', onChunk);
    socket.on('connect', () => console.log('connected'));
    socket.on('error', onError);

    return () => {
      socket.off("HyDE", onHyDE);
      socket.off("sources", onSources);
      socket.off("output", onChunk);
      socket.off("connect");
      socket.off("error");
    };
  }, []);

  useEffect(() => {
    chatRef.current = chat;
  }, [chat]);

  useEffect(() => {
    if (chat.length !== 0 && chat[chat.length - 1].user === 'user') {
      if (isSpectrum) {
        socket.emit('submit_chat_spectrum', { 
          "chat": chat,
        });
      } else {
        socket.emit('submit_chat', { "chat": chat });
      }
      lastRef.current?.scrollIntoView({ behavior: 'smooth' });
      setIsTyping(true);
    }
    sendHeightToParent();
  }, [chat, isSpectrum]);

  const handleSubmit = (e) => {
    e?.preventDefault();
    if (!prompt.trim()) return;
    
    setChat(chat => [...chat, { user: "user", text: prompt.trim(), sources: null }]);
    setPrompt('');
  };

  // useEffect(() => {
  //   const handleResize = () => sendHeightToParent();
  //   window.addEventListener('resize', handleResize);
    
  //   // Initial height calculation
  //   sendHeightToParent();
    
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

  const handleEdit = useCallback((messageId, newText) => {
    setChat(prev => {
      // Keep all messages up to and including the edited message
      const newChat = prev.slice(0, messageId + 1);
      // Update the edited message
      newChat[messageId] = {
        ...newChat[messageId],
        text: newText,
      };
      return newChat;
    });
  
    // Clear the typing indicator since we're starting fresh
    setIsTyping(false);
  
    // Give state a chance to update before emitting
    setTimeout(() => {
      if (isSpectrum) {
        socket.emit('submit_chat_spectrum', { 
          chat: chatRef.current.slice(0, messageId + 1),
        });
      } else {
        socket.emit('submit_chat', { 
          chat: chatRef.current.slice(0, messageId + 1)
        });
      }
    }, 0);
  }, [isSpectrum]);

  const handleRetry = useCallback((messageId) => {
    // Remove the message and any subsequent messages
    setChat(prev => prev.slice(0, messageId));
  
    // Clear the typing indicator since we're starting fresh
    setIsTyping(false);
  
    // Give state a chance to update before emitting
    setTimeout(() => {
      if (isSpectrum) {
        socket.emit('submit_chat_spectrum', { 
          chat: chatRef.current.slice(0, messageId),
        });
      } else {
        socket.emit('submit_chat', { 
          chat: chatRef.current.slice(0, messageId)
        });
      }
    }, 0);
  }, [isSpectrum]);

  return (
    <ErrorBoundary>
    <div className={`flex flex-col bg-gray-300 ${isSpectrum ? '' : 'min-h-screen'}`}>
      {/* Header */}
      <header className={`shadow-sm sticky top-0 z-10 ${isSpectrum ? '' : 'bg-white border-b'}`}>
        <div className="max-w-5xl mx-auto py-4 px-6">
          <div className={`flex items-center ${isSpectrum ? 'justify-center' : ''} gap-4`}>
          {isSpectrum ? <p><img className="w-12 h-12" src="/chattpi_720.png" alt="ChatTPI"/></p> : <p><img className="align-baseline inline w-24 h-24" src="/chattpi_720.png" alt="ChatTPI"/> by the  <a href="https://techpolicyinstitute.org">Technology Policy Institute</a></p>}
            <p className="text-sm text-gray-600">
              {isSpectrum 
                ? "Chat with the transcripts of TPI's 2025 Spectrum Series panels" 
                : "Chat with TPI's archive of sources"
              }
            </p>
          </div>
        </div>
      </header>

      {/* Main Chat Area */}
      <main className="flex-1"> {/* Added padding to account for fixed input overflow-y-auto*/} 
        <ErrorBoundary>
          <div className={`max-w-5xl mx-auto flex flex-col ${isSpectrum ? '' : 'h-full'} relative`}>
            <div className={`flex-1 overflow-y-auto px-4 ${isSpectrum ? 'py-1' : 'py-8'}`}>
              {chat.length === 0 ? (
                <div className="max-w-3xl mx-auto">
                  <div className={`bg-red-50 border border-red-200 rounded-lg py-4 pl-4 ${isSpectrum ? 'pr-0' : 'pr-4 sm:pr-6'}`}>
                    <div className="flex items-start gap-3">
                      {isSpectrum ? '' : <AlertCircle className="w-5 h-5 text-red-600 flex-shrink-0 mt-0.5" />}
                      {isSpectrum ? 
                      <div className="flex-1 pr-0 text-center justify-center">
                      <p className="mt-2 text-sm text-gray-600 flex items-center text-center justify-center">
                      <AlertCircle className="mr-1 w-5 h-5 text-red-600 flex-shrink-0 mt-0.5" />
                        This AI tool may make mistakes. Verify information before citing.</p>
                        {/* Important Disclaimer for Transcript LLM</p>
                      <div className="mt-2 text-sm text-gray-600">
                        <p>Note: This AI tool helps explore TPI 2025 Winter Spectrum Series transcripts but:</p>
                        <ul className="list-disc list-inside ml-4 mr-0">
                        <li>May misinterpret information</li>
                        <li>Might generate incorrect summaries</li>
                        <li>Only knows what's in the provided transcripts</li>
                        </ul>
                        </div>
                        <p className="mt-2 text-sm text-gray-600"><b>Always check original transcripts before citing any information</b></p> */}
                        </div>
                        : <div>
                        <p className="mt-2 text-sm text-gray-600">
                        Disclaimer: ChatTPI is an AI-based chatbot we developed to help users learn from TPI's 15-year collection of research, events, podcasts, and more. However, please note that because ChatTPI is based on generative AI, the responses it provides may not necessarily reflect the views, opinions, or positions of our organization.
                        </p>
                        <p className="mt-2 text-sm text-gray-600">
                        We strongly recommend that users check the sources ChatTPI provides and also independently verify information.
                        </p>
                        <p className="mt-2 text-sm text-gray-600">
                          If you have any concerns or feedback regarding the chatbot's responses, please contact Nathaniel Lovin at nlovin@techpolicyinstitute.org.
                        </p>
                      </div>}
                    </div>
                  </div>
                </div>
              ) : (<VirtualizedChatContainer
                chat={chat}
                lastRef={lastRef}
                isTyping={isTyping}
                onEdit={handleEdit}
                onRetry={handleRetry}
                isSpectrum={isSpectrum}
              />)}
            </div>

            {/* Input Area */}
            <div ref={inputRef} className={`bottom-0 left-0 right-0 p-4 shadow-lg ${isSpectrum ? '' : 'fixed bg-white border-t'}`}>
              <div className="max-w-3xl mx-auto flex items-center gap-4">
                <form onSubmit={handleSubmit} className="flex-1 flex gap-4">
                  <textarea
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        handleSubmit();
                      }
                    }}
                    placeholder="Type your message..."
                    className="flex-1 resize-none rounded-lg border border-gray-200 p-3 focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent"
                    rows="2"
                  />
                  <div className="flex flex-col gap-2">
                    <button
                      type="submit"
                      disabled={!prompt.trim()}
                      className="h-12 px-4 rounded-lg bg-red-500 text-white hover:bg-red-600 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                    >
                      <Send className="w-5 h-5" />
                    </button>
                    {chat.length > 0 && (
                      <button
                        type="button"
                        onClick={() => setChat([])}
                        className="h-12 px-4 rounded-lg border border-gray-200 text-gray-600 hover:bg-gray-50 transition-colors"
                      >
                        <Trash2 className="w-5 h-5" />
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </ErrorBoundary>
      </main>
    </div>
    </ErrorBoundary>
  );
}

export default App;